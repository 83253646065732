import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProductsList } from '../../../admin/hooks';
import { ControlledAutocomplete, useDebouncedState } from '../../../shared';

interface Props {
    name: string;
    isHidden: boolean;
}

export const EntrySelectProduct: FC<Props> = ({ name, isHidden }) => {
    const { t } = useTranslation();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: products, isPending: productsIsLoading } = useProductsList({
        isDraft: false,
        search: debouncedSearch,
        pageSize: 50,
    });
    const watchIsFeadProductsPattern = useWatch({ name: 'feadProductsPattern' });

    return (
        <ControlledAutocomplete
            name={name}
            defaultValue={null}
            loading={productsIsLoading}
            options={debouncedSearch ? products?.data || [] : []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(category) => category.name}
            noOptionsText={debouncedSearch ? t('noProductsFound') : t('startSearchingProducts')}
            onInputChange={(e, value) => setSearch(value)}
            size="small"
            hidden={isHidden}
            getOptionDisabled={(product) =>
                watchIsFeadProductsPattern ? !product?.requiresReservation : product?.requiresReservation
            }
        />
    );
};
